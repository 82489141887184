import React, { Component, useEffect, useRef } from "react"
import { useState } from "react"
import { fetchApi, patchApi, postApi } from "../Utils/WebApi-utils"
import { useMsal } from "@azure/msal-react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import axios from "axios"
import { authToken } from "../Security/authToken"
import { Controller, useForm } from "react-hook-form"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { underlineButton, down, right, saveButton } from "../Styling/Buttons"
import "../Styling/styles.css"
import LoadingModal from "../Modal/LoadingModal"
import { compile } from "html-to-text"
import { StringParam, useQueryParam } from "use-query-params"
import moment from "moment"
import download from "downloadjs"
import {
  InputCase,
  InputCaseError,
  selectValueError,
} from "../Styling/InputField"

export type UploadFile = {
  name: string
  type: string
}

export type FileAttachment = {
  name: string
  mimeType: string
  document: string
}

export const ActiveCase = () => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  const { handleSubmit, reset } = useForm()
  const [message, setMessage] = useState("")
  const [error, setError] = useState(false)
  const [selectedFile, setSelectedFile] = useState<UploadFile>()
  const [fileReader, setFileReader] = useState<any>({})
  const [messageTimeSent, setMessageTimeSent] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingError, setLoadingError] = useState(false)

  const [regardingObjectId, setRegardingObjectId] = useQueryParam(
    "id",
    StringParam
  )

  //A styled button that clicks on a hidden input to upload files
  const inputRef = useRef(null)

  const onUploadButtonClick = () => {
    inputRef.current.click()
  }

  const GetIncident = (account, inProgress, instance) => {
    return useQuery(
      "getIncidents",
      async () => {
        const token = await authToken(account, inProgress, instance)
        //await new Promise(resolve => setTimeout(resolve, 500))
        if (token?.length > 0) {
          return axios
            .get(process.env.GATSBY_APP_WEBAPI + "HefIncident/me/active", {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization: "Bearer " + token,
              },
            })
            .then(res => {
              return res.data
            })
        } else {
          return null
        }
      },
      {
        refetchOnWindowFocus: false,
        // enabled: seeCases,
        retry: 3,
      }
    )
  }

  const MyIncidents = GetIncident(accounts, inProgress, instance)

  console.log(MyIncidents)

  const GetMessages = (account, inProgress, instance) => {
    return useQuery(
      "getMessages",
      async () => {
        const token = await authToken(account, inProgress, instance)
        //await new Promise(resolve => setTimeout(resolve, 500))
        if (token?.length > 0) {
          return axios
            .get(
              process.env.GATSBY_APP_WEBAPI +
                "HefMessages/Messages?regardingObjectId=" +
                regardingObjectId,
              {
                headers: {
                  "Conent-Type": "application/json",
                  "Ocp-Apim-Subscription-Key":
                    process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then(res => {
              return res.data
            })
        } else {
          return null
        }
      },
      {
        refetchOnWindowFocus: false,
        // enabled: seeCases,
        retry: 1,
      }
    )
  }

  const MyMessages = GetMessages(accounts, inProgress, instance)

  console.log(MyMessages)

  console.log(regardingObjectId)

  const convert = compile({
    presverveNewlines: true,
    wordwrap: 130,
  })

  const CreateMessage = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefMessages",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        toast.success("Meldingen ble sendt", {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries(["getConnections"])
        console.log(data)
        setMessage("")
        setSelectedFile({
          name: "",
          type: "",
        })
        setFileReader({})
        MyMessages.refetch()
      },
      onError: data => {
        toast.error("Noe gikk galt med meldingen!")
      },
    }
  )

  const downloadEmailAttachment = async (attachmentId, attachmentName) => {
    //await new Promise(resolve => setTimeout(resolve, 500))
    return axios
      .get(
        process.env.GATSBY_APP_WEBAPI +
          "HefMessages/DownloadAttachament?emailAttachment=" +
          attachmentId,
        {
          headers: {
            "Conent-Type": "application/pdf",
            "Ocp-Apim-Subscription-Key":
              process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
            Authorization:
              "Bearer " + (await authToken(accounts, inProgress, instance)),
          },
          responseType: "blob",
        }
      )
      .then(res => {
        download(res.data, attachmentName)
      })
      .catch(e => toast.error(e))
  }

  const downloadMessageAttachment = async (attachmentId, attachmentName) => {
    //await new Promise(resolve => setTimeout(resolve, 500))
    return axios
      .get(
        process.env.GATSBY_APP_WEBAPI +
          "HefMessages/DownloadAttachament?messageAttachment=" +
          attachmentId,
        {
          headers: {
            "Conent-Type": "application/pdf",
            "Ocp-Apim-Subscription-Key":
              process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
            Authorization:
              "Bearer " + (await authToken(accounts, inProgress, instance)),
          },
          responseType: "blob",
        }
      )
      .then(res => {
        download(res.data, attachmentName)
      })
      .catch(e => toast.error(e))
  }

  let fileAttachment: FileAttachment = {
    name: "",
    mimeType: "",
    document: "",
  }

  const changeHandler = e => {
    if (e.target.files[0] !== undefined) {
      setSelectedFile(e.target.files[0])
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = function () {
          resolve(reader.result)
        }
        setFileReader(reader)
      })
    }
  }

  const onSubmitMessage = () => {
    let base64: any
    if (fileReader.result != null || fileReader.result != undefined) {
      base64 = fileReader.result
      base64 = base64.split("base64,")[1]
      fileAttachment = {
        name: selectedFile.name,
        mimeType: selectedFile.type,
        document: base64,
      }
    } else {
      fileAttachment = null
    }

    if (
      selectedFile == undefined ||
      (selectedFile.name == "" && selectedFile.type == "")
    ) {
      // If the object have no attachment
      let messageObject: any = {
        regardingObjectId: regardingObjectId,
        subject: "Test subject",
        description: message,
        direction: 778380000,
      }
      // Description needs a value
      if (message == "") {
        setError(true)
      } else {
        setError(false)
        setMessageTimeSent(new Date().toLocaleTimeString())
        CreateMessage.mutate(messageObject)
      }
    }
    // If the object have an attachemnt
    else {
      let messageObjectWithAttachment: any = {
        regardingObjectId: regardingObjectId,
        subject: "Test subject",
        description: message,
        direction: 778380000,
        attachment: {
          subject: fileAttachment.name,
          fileName: fileAttachment.name,
          documentBody: fileAttachment.document,
          mimetype: fileAttachment.mimeType,
        },
      }
      // Description needs a value
      if (message == "") {
        setError(true)
      } else {
        setError(false)
        setMessageTimeSent(new Date().toLocaleTimeString())
        CreateMessage.mutate(messageObjectWithAttachment)
      }
    }
  }

  useEffect(() => {
    if (
      MyIncidents.isSuccess &&
      MyIncidents?.data == null &&
      MyMessages.isSuccess &&
      MyMessages?.data == null
    ) {
      MyIncidents.refetch()
      MyMessages.refetch()
    }
  }, [MyIncidents.isSuccess && MyMessages.isSuccess])

  useEffect(() => {
    if (
      (MyMessages.isSuccess && MyMessages.isRefetching == true) ||
      (MyIncidents.isSuccess && MyIncidents.isRefetching == true)
    ) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [
    MyMessages.isSuccess,
    MyMessages.isRefetching,
    MyIncidents.isSuccess,
    MyIncidents.isRefetching,
  ])

  //Remove error message if all fields have a value
  useEffect(() => {
    if (message != "" && error == true) setError(false)
  }, [message])

  return (
    <div>
      <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
        <div className="lg:min-w-[50%] lg:max-w-[50%]">
          <div className="flex flex-col">
            {MyIncidents.isLoading ||
            MyMessages.isLoading ||
            CreateMessage.isLoading ||
            loading == true ? (
              <LoadingModal />
            ) : (
              <div>
                {MyIncidents.data?.map((incident, i) => (
                  <div key={i}>
                    {incident?.incidentId === regardingObjectId ? (
                      <div className="flex flex-col">
                        <h1 className="font-serif text-blue text-xl font-normal mb-4">
                          {incident?.caseTitle}
                        </h1>
                        <p className="font-sans text-base mb-8">
                          Saksnummer{" "}
                          <span className="font-sans text-base font-bold">
                            {incident?.caseNumber}
                          </span>
                        </p>
                      </div>
                    ) : null}
                  </div>
                ))}
                {/* Needs a slice to remove the last value in the map array. The last value in the array is not needed here because it will be picked up in MyIncidents with the right attachment */}
                {MyMessages.data?.slice(0, -1)?.map((messages, i) => (
                  <div key={i}>
                    {messages.regardingId === regardingObjectId ? (
                      <div className="flex flex-col">
                        <div className="bg-white pt-2 pb-4 px-4 mb-8">
                          <div className="flex flex-col">
                            {messages?.isSender == 1 ? (
                              <div className="mb-2">
                                <label className="font-sans text-xs font-bold">
                                  Deg
                                </label>
                              </div>
                            ) : (
                              <div className="mb-2">
                                <label className="font-sans text-xs font-bold">
                                  HEF
                                </label>
                              </div>
                            )}
                            <p className="font-sans text-xs mb-0">
                              {moment(messages?.createdOn).format("DD.MM.YYYY")}
                            </p>
                            <p className="font-sans text-xs mt-4 mb-0">
                              {[
                                messages?.description?.replaceAll(
                                  "&nbsp;",
                                  " "
                                ),
                              ].map(convert)}
                            </p>
                            {messages?.emailAttachments?.map(
                              (attachment, i) => (
                                <div key={i}>
                                  <button
                                    onClick={() => {
                                      downloadEmailAttachment(
                                        attachment.attachmentId,
                                        attachment.fileName
                                      )
                                    }}
                                    className={
                                      " border-2 border-blue p-1 px-3 pt-1 pb-1 mr-8 mt-4 font-sans text-xs text-blue fill-current hover:fill-current hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t flex flex-row items-center " +
                                      down
                                    }
                                  >
                                    <svg
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mr-2"
                                    >
                                      <path
                                        d="M19.88 12.9679C21.104 14.1919 21.752 15.5599 21.752 16.9279C21.752 19.3279 19.688 21.4159 17.288 21.4159C15.92 21.4159 14.48 20.7439 13.28 19.5439L1.736 7.99992C0.752 7.01592 0.2 5.76792 0.2 4.59192C0.2 2.69592 2 0.991921 3.968 0.991921C5.168 0.991921 6.248 1.47192 7.256 2.45592L15.44 10.6159C16.208 11.3599 16.616 12.2479 16.616 13.1359C16.616 14.8879 15.2 16.3519 13.472 16.3519C12.632 16.3519 11.768 15.9439 10.952 15.1279L5.984 10.1359C5.816 9.96792 5.768 9.84792 5.768 9.60792C5.768 9.29592 6.008 9.05592 6.296 9.05592C6.536 9.05592 6.68 9.12792 6.848 9.29592L11.84 14.2639C12.392 14.7919 12.92 15.0559 13.424 15.0559C14.552 15.0559 15.344 14.2159 15.344 13.0399C15.344 12.5359 15.08 12.0319 14.552 11.5039L6.368 3.34392C5.648 2.64792 4.832 2.26392 4.064 2.26392C2.72 2.26392 1.448 3.46392 1.448 4.68792C1.448 5.47992 1.88 6.39192 2.6 7.11192L14.168 18.6799C15.176 19.6879 16.184 20.1919 17.216 20.1919C19.04 20.1919 20.504 18.7279 20.504 16.9279C20.504 15.8719 20 14.8639 18.992 13.8559L10.64 5.50392C10.472 5.33592 10.4 5.19192 10.4 4.95192C10.4 4.63992 10.688 4.37592 11 4.37592C11.168 4.37592 11.384 4.47192 11.528 4.61592L19.88 12.9679Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                    {attachment.fileName}
                                  </button>
                                </div>
                              )
                            )}
                            {messages?.messageAttachments?.map(
                              (attachment, i) => (
                                <div key={i}>
                                  <button
                                    onClick={() => {
                                      downloadMessageAttachment(
                                        attachment.attachmentId,
                                        attachment.fileName
                                      )
                                    }}
                                    className={
                                      " border-2 border-blue p-1 px-3 pt-1 pb-1 mr-8 mt-4 font-sans text-xs text-blue fill-current hover:fill-current hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t flex flex-row items-center " +
                                      down
                                    }
                                  >
                                    <svg
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mr-2"
                                    >
                                      <path
                                        d="M19.88 12.9679C21.104 14.1919 21.752 15.5599 21.752 16.9279C21.752 19.3279 19.688 21.4159 17.288 21.4159C15.92 21.4159 14.48 20.7439 13.28 19.5439L1.736 7.99992C0.752 7.01592 0.2 5.76792 0.2 4.59192C0.2 2.69592 2 0.991921 3.968 0.991921C5.168 0.991921 6.248 1.47192 7.256 2.45592L15.44 10.6159C16.208 11.3599 16.616 12.2479 16.616 13.1359C16.616 14.8879 15.2 16.3519 13.472 16.3519C12.632 16.3519 11.768 15.9439 10.952 15.1279L5.984 10.1359C5.816 9.96792 5.768 9.84792 5.768 9.60792C5.768 9.29592 6.008 9.05592 6.296 9.05592C6.536 9.05592 6.68 9.12792 6.848 9.29592L11.84 14.2639C12.392 14.7919 12.92 15.0559 13.424 15.0559C14.552 15.0559 15.344 14.2159 15.344 13.0399C15.344 12.5359 15.08 12.0319 14.552 11.5039L6.368 3.34392C5.648 2.64792 4.832 2.26392 4.064 2.26392C2.72 2.26392 1.448 3.46392 1.448 4.68792C1.448 5.47992 1.88 6.39192 2.6 7.11192L14.168 18.6799C15.176 19.6879 16.184 20.1919 17.216 20.1919C19.04 20.1919 20.504 18.7279 20.504 16.9279C20.504 15.8719 20 14.8639 18.992 13.8559L10.64 5.50392C10.472 5.33592 10.4 5.19192 10.4 4.95192C10.4 4.63992 10.688 4.37592 11 4.37592C11.168 4.37592 11.384 4.47192 11.528 4.61592L19.88 12.9679Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                    {attachment.fileName}
                                  </button>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
                {MyIncidents?.data?.map((incident, i) => (
                  <div key={i}>
                    {incident.incidentId === regardingObjectId ? (
                      <div className="flex flex-col">
                        <div className="bg-white pt-2 pb-4 px-4 mb-8">
                          <div className="flex flex-col">
                            <div className="mb-2">
                              <label className="font-sans text-xs font-bold">
                                Deg
                              </label>
                            </div>
                            <p className="font-sans text-xs mb-0">
                              {moment(incident?.createdOn).format("DD.MM.YYYY")}
                            </p>
                            <p className="font-sans text-xs mt-4 mb-0">
                              {incident?.description}
                            </p>
                            {incident?.attachment?.map((attachment, i) => (
                              <div key={i}>
                                <button
                                  onClick={() => {
                                    downloadMessageAttachment(
                                      attachment.attachmentId,
                                      attachment.fileName
                                    )
                                  }}
                                  className={
                                    " border-2 border-blue p-1 px-3 pt-1 pb-1 mr-8 mt-4 font-sans text-xs text-blue fill-current hover:fill-current hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t flex flex-row items-center " +
                                    down
                                  }
                                >
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mr-2"
                                  >
                                    <path
                                      d="M19.88 12.9679C21.104 14.1919 21.752 15.5599 21.752 16.9279C21.752 19.3279 19.688 21.4159 17.288 21.4159C15.92 21.4159 14.48 20.7439 13.28 19.5439L1.736 7.99992C0.752 7.01592 0.2 5.76792 0.2 4.59192C0.2 2.69592 2 0.991921 3.968 0.991921C5.168 0.991921 6.248 1.47192 7.256 2.45592L15.44 10.6159C16.208 11.3599 16.616 12.2479 16.616 13.1359C16.616 14.8879 15.2 16.3519 13.472 16.3519C12.632 16.3519 11.768 15.9439 10.952 15.1279L5.984 10.1359C5.816 9.96792 5.768 9.84792 5.768 9.60792C5.768 9.29592 6.008 9.05592 6.296 9.05592C6.536 9.05592 6.68 9.12792 6.848 9.29592L11.84 14.2639C12.392 14.7919 12.92 15.0559 13.424 15.0559C14.552 15.0559 15.344 14.2159 15.344 13.0399C15.344 12.5359 15.08 12.0319 14.552 11.5039L6.368 3.34392C5.648 2.64792 4.832 2.26392 4.064 2.26392C2.72 2.26392 1.448 3.46392 1.448 4.68792C1.448 5.47992 1.88 6.39192 2.6 7.11192L14.168 18.6799C15.176 19.6879 16.184 20.1919 17.216 20.1919C19.04 20.1919 20.504 18.7279 20.504 16.9279C20.504 15.8719 20 14.8639 18.992 13.8559L10.64 5.50392C10.472 5.33592 10.4 5.19192 10.4 4.95192C10.4 4.63992 10.688 4.37592 11 4.37592C11.168 4.37592 11.384 4.47192 11.528 4.61592L19.88 12.9679Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  {attachment.fileName}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
                <div className="font-sans flex flex-col space-y-2 mt-8">
                  <label className="text-blue font-sans text-sm">Svar</label>
                  <div>
                    <form
                      name="case"
                      method="patch"
                      onSubmit={handleSubmit(onSubmitMessage)}
                    >
                      <div className="flex flex-col">
                        <textarea
                          placeholder="Skriv her..."
                          className={
                            message == "" && error == true
                              ? InputCaseError
                              : InputCase
                          }
                          onChange={e => {
                            setMessage(e.target.value)
                          }}
                          value={message}
                          maxLength={2000}
                        ></textarea>
                        {error ? (
                          <div className="font-sans text-orange-dark mt-4">
                            <p className="mb-0">
                              Du har glemt å skrive en melding
                            </p>
                          </div>
                        ) : null}
                        <div className="mt-4">
                          <button
                            type="button"
                            className={
                              " border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 mb-4 font-sans text-blue fill-current hover:fill-current hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t flex flex-row items-center " +
                              right
                            }
                            onClick={onUploadButtonClick}
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-2"
                            >
                              <path
                                d="M19.88 12.9679C21.104 14.1919 21.752 15.5599 21.752 16.9279C21.752 19.3279 19.688 21.4159 17.288 21.4159C15.92 21.4159 14.48 20.7439 13.28 19.5439L1.736 7.99992C0.752 7.01592 0.2 5.76792 0.2 4.59192C0.2 2.69592 2 0.991921 3.968 0.991921C5.168 0.991921 6.248 1.47192 7.256 2.45592L15.44 10.6159C16.208 11.3599 16.616 12.2479 16.616 13.1359C16.616 14.8879 15.2 16.3519 13.472 16.3519C12.632 16.3519 11.768 15.9439 10.952 15.1279L5.984 10.1359C5.816 9.96792 5.768 9.84792 5.768 9.60792C5.768 9.29592 6.008 9.05592 6.296 9.05592C6.536 9.05592 6.68 9.12792 6.848 9.29592L11.84 14.2639C12.392 14.7919 12.92 15.0559 13.424 15.0559C14.552 15.0559 15.344 14.2159 15.344 13.0399C15.344 12.5359 15.08 12.0319 14.552 11.5039L6.368 3.34392C5.648 2.64792 4.832 2.26392 4.064 2.26392C2.72 2.26392 1.448 3.46392 1.448 4.68792C1.448 5.47992 1.88 6.39192 2.6 7.11192L14.168 18.6799C15.176 19.6879 16.184 20.1919 17.216 20.1919C19.04 20.1919 20.504 18.7279 20.504 16.9279C20.504 15.8719 20 14.8639 18.992 13.8559L10.64 5.50392C10.472 5.33592 10.4 5.19192 10.4 4.95192C10.4 4.63992 10.688 4.37592 11 4.37592C11.168 4.37592 11.384 4.47192 11.528 4.61592L19.88 12.9679Z"
                                fill="currentColor"
                              />
                            </svg>
                            Legg til vedlegg
                          </button>
                          <input
                            id="fileUpload"
                            ref={inputRef}
                            type="file"
                            accept="image/*,.pdf"
                            onChange={changeHandler}
                            hidden
                          />
                          <p className="mb-0">{selectedFile?.name}</p>
                        </div>
                        <div className="mt-8">
                          <button className={saveButton + right}>
                            Send nå
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              setMessage("")
                              setSelectedFile({
                                name: "",
                                type: "",
                              })
                              setFileReader({})
                            }}
                            className={underlineButton}
                          >
                            Forkast
                          </button>
                        </div>
                      </div>
                    </form>
                    {messageTimeSent == "" ? null : (
                      <div>
                        <p className="font-sans mb-0">
                          Sendt kl. {messageTimeSent.slice(0, 5)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
